exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juridisch-js": () => import("./../../../src/pages/juridisch.js" /* webpackChunkName: "component---src-pages-juridisch-js" */),
  "component---src-pages-praktijkgebieden-js": () => import("./../../../src/pages/praktijkgebieden.js" /* webpackChunkName: "component---src-pages-praktijkgebieden-js" */),
  "component---src-pages-publicaties-avt-js": () => import("./../../../src/pages/publicatiesAVT.js" /* webpackChunkName: "component---src-pages-publicaties-avt-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-vacatures-js": () => import("./../../../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */)
}

